import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import Zoom from 'react-reveal/Zoom';
import PropTypes from 'prop-types';

import check from '../images/checked.svg';
import divider from '../images/divider-colored.svg';

import '../styles/global.scss';

// You can use this `calc` method to increase the impact
// of the effect by playing around with the values and units.

const calc = o => `translateY(${o * 0.02}px)`;

const Products = ({ data }) => {
  return (
    <section className='section'>
      {data.map((data, index) =>
        index % 2 === 0 ? (
          <div className='columns top'>
            <>
              <Zoom left>
                <div className='column left-col-prod'>
                  <div className='title-prod'>
                    <img src={divider} alt='divider' />
                    <div> {data.title}</div>
                  </div>
                  <div className='prod-description'>
                    {data.description.split('\n').map((item, i) => (
                      <span style={{ display: 'flex' }}>
                        <img
                          src={check}
                          width='17'
                          alt='check'
                          style={{
                            marginRight: '15px',
                            height: '20px',
                            marginTop: '12px',
                          }}
                        />
                        <div key={i} className='prod-description-text'>
                          {item}
                        </div>
                      </span>
                    ))}
                  </div>
                </div>
              </Zoom>
              <div className='column'>
                <animated.div>
                  <center>
                    <Zoom right>
                      <img 
                        src={data.file.url}
                        className='phone contain'
                        alt={data.title}
                      />
                    </Zoom>
                  </center>
                </animated.div>
              </div>
            </>
          </div>
        ) : (
          <div className='columns is-centered is-center-odd'>
            <>
              <div className='column'>
                <animated.div>
                  <center>
                    <Zoom left>
                      <img
                        src={data.file.url}
                        className='phone contain'
                        alt={data.title}
                      />
                    </Zoom>
                  </center>
                </animated.div>
              </div>
              <div className='column left-col-prod'>
                <Zoom right>
                  <div className='title-prod'>
                    <img src={divider} alt='divider' />
                    <div> {data.title}</div>
                  </div>
                  <div className='prod-description'>
                    {data.description.split('\n').map((item, i) => (
                      <span style={{ display: 'flex' }}>
                        <img
                          src={check}
                          width='17'
                          alt='check'
                          style={{
                            marginRight: '15px',
                            height: '20px',
                            marginTop: '12px',
                          }}
                        />
                        <div key={i} className='prod-description-text'>
                          {item}
                        </div>
                      </span>
                    ))}
                  </div>
                </Zoom>
              </div>
            </>
          </div>
        )
      )}
    </section>
  );
};

Products.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func,
  }),
};

export default Products;
